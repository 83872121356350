import React from 'react';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

export default function LicenseNotice() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Surveyz.xyz - '}
            <Link color="inherit" target="_blank" rel="noopener" href="https://github.com/jpiechowka/surveyz.xyz-frontend/blob/master/LICENSE">
                {'Licensed under Apache License 2.0'}
            </Link>{' '}
        </Typography>
    );
}
