import React from 'react';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

export default function ThemeChangerSlide() {
    const [state, setState] = React.useState({
        isDarkTheme: true,
    });

    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
    };

    return (
        <FormGroup row>
            <FormControlLabel
                control={<Switch checked={state.isDarkTheme} onChange={handleChange} name="isDarkTheme" />}
                label="Dark Theme"
            />
        </FormGroup>
    );
}
