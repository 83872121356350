import React from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import ProTip from './ProTip';
import TopAppBar from './components/TopAppBar'
import LicenseNotice from './components/LicenseNotice'

function App() {
  return (
    <div>
      <TopAppBar />
      <Container maxWidth="sm">
        <Box my={4}>
          <Typography variant="h4" component="h1" gutterBottom>Create React App v4-beta example</Typography>
          <ProTip />
          <LicenseNotice />
        </Box>
      </Container>
    </div>
  );
}

export default App;
